<template>

    <td :class="props.notification.statusTypeId == 1 ? 'new-notification' : 'clicked-notification'">
        <div v-if="props.notification.statusTypeId == 1" class="d-flex justify-content-center align-items-center">
            <div class="new-badge">NEW</div>
            <div class="new-notification-word">
                {{ props.notification.notificationWord }}
            </div>
        </div>

        <div v-else-if="props.notification.statusTypeId == 2" class="notif-div">
            <div class="clicked-badge">VIEWED</div>
            <div class="notification-word">
                {{ props.notification.notificationWord }}
            </div>
        </div>

        <div v-else-if="props.notification.statusTypeId == 4" class="notif-div">
            <div class="qualified-badge">QUALIFIED</div>
            <div class="notification-word">
                {{ props.notification.notificationWord }}
            </div>
        </div>

        <div v-else-if="props.notification.statusTypeId == 5" class="notif-div">
            <div class="not-qualified-badge">NOT QUALIFIED</div>
            <div class="notification-word">
                {{ props.notification.notificationWord }}
            </div>
        </div>

        <div v-else class="notif-div">
            <div class="reviewed-badge">INGESTED</div>
            <div class="notification-word">
                {{ props.notification.notificationWord }}
            </div>
        </div>

    </td>

    <td :class="props.notification.statusTypeId == 1 ? 'new-notification' : 'clicked-notification'">
        {{ props.notification.socialMediaPlatform }}
    </td>

    <td :class="props.notification.statusTypeId == 1 ? 'new-notification' : 'clicked-notification'">
        {{ props.notification.dateAdded }}
    </td>

    <td :class="props.notification.statusTypeId == 1 ? 'new-notification' : 'clicked-notification'">
        {{ props.notification.creatorName }}
    </td>

    <td :class="props.notification.statusTypeId == 1 ? 'new-notification' : 'clicked-notification'">
        <div class="action-column">
            <MDBDropdown v-model="rowOverflow" class="kebab">
                <MDBDropdownToggle @click.stop="rowOverflow = !rowOverflow">
                    <span class="fas fa-ellipsis-v"></span>
                </MDBDropdownToggle>
                <MDBDropdownMenu aria-labelledby="dropdownMenuButton">
                    <MDBDropdownItem href="#" @click.stop.prevent="dropdownAction(props.notification, 'qualified')">Mark
                        as Qualified</MDBDropdownItem>
                    <MDBDropdownItem href="#" @click.stop.prevent="dropdownAction(props.notification, 'notQualified')">
                        Mark as Not Qualified</MDBDropdownItem>
                    <MDBDropdownItem href="#" @click.stop.prevent="dropdownAction(props.notification, 'reviewed')">Mark
                        as Ingested</MDBDropdownItem>
                </MDBDropdownMenu>
            </MDBDropdown>
        </div>
    </td>
</template>

<script>
import { MDBDropdown, MDBDropdownToggle, MDBDropdownMenu, MDBDropdownItem } from 'mdb-vue-ui-kit';
import { ref } from 'vue';
import axios from "axios";
import { useToastStore } from "../store/toastStore";

export default {
    components: {
        MDBDropdown,
        MDBDropdownToggle,
        MDBDropdownMenu,
        MDBDropdownItem,
    },
    props: ["notification"],
    setup(props) {
        const rowOverflow = ref(false);
        const toastStore = useToastStore();
        const dropdownAction = (row, action) => {
            var status = 3; // reviewed
            var successMessage = "Successfully tagged as reviewed!";
            if (action == 'qualified') {
                status = 4;
                successMessage = "Successfully tagged as qualified!"
            } else if (action == 'notQualified') {
                status = 5;
                successMessage = "Successfully tagged as not qualified."
            }

            updateNotificationStatus(row.id, status, successMessage);
            rowOverflow.value = false;

            row.statusTypeId = status;
        };

        const updateNotificationStatus = (notificationId, statusId, successMessage) => {
            const toastSuccess = {
                ...toastStore.toastStatus.success,
                ...{
                    message: successMessage,
                },
            };

            const formData = {
                status: statusId
            };

            var url = "api/notifications/" + notificationId + "/status";
            axios
                .patch(url, formData, {
                    headers: {
                        'Accept': 'application/json, text/plain, */*',
                        'Content-Type': 'application/json'
                    },
                })
                .then(function (response) {
                    if (response.status == 200) {
                        toastStore.toastObject = {
                            ...toastStore.toastObject,
                            ...toastSuccess,
                        };
                    }

                    if (response.status == 422) {
                        toastStore.toastObject = {
                            ...toastStore.toastObject,
                            ...toastStore.toastStatus.fail,
                        };
                    }
                })
                .catch(function () {
                    toastStore.toastObject = {
                        ...toastStore.toastObject,
                        ...toastStore.toastStatus.fail,
                    };
                });
        }

        return {
            rowOverflow,
            props,
            dropdownAction
        };
    }
}
</script>

<style lang="scss" scoped>
:deep(.dropdown) {
    &.kebab {
        .fa-ellipsis-v {
            font-size: 16px;
            color: #5A5A5A;
            line-height: revert;
        }

        .dropdown-toggle {
            width: 24px;
            margin: 0 0 0 8px;
            border: none;
            background-color: transparent;
            box-shadow: unset;
            padding: 3px;
            vertical-align: -webkit-baseline-middle;

            &:hover {
                background-color: #E7E6E4;
                box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
                border-radius: 50%;
                width: 30px;
            }

            &::after {
                display: none;
            }
        }
    }
}

.new-badge {
    background-color: #17AD49;
    color: white;
    font-size: 12px;
    letter-spacing: .5px;
    padding: 0 10px;
    border-radius: 2px;
    border-color: #17AD49;
    height: 18px;
    line-height: 20px;
    width: 10%;
    text-align: center;
}

.notification-word {
    font-weight: 400;
    width: 89%;
}

.new-notification-word {
    font-weight: 600;
    width: 89%;
}

.new-notification {
    background-color: #bcdaf7;
}

.notif-div {
    display: flex;
    width: 100%;
}

.clicked-notification {
    background-color: #dfe5f0;
}

.clicked-badge {
    background-color: #497df5;
    color: white;
    font-size: 12px;
    letter-spacing: .5px;
    padding: 0 4px;
    border-radius: 2px;
    border-color: #17AD49;
    margin-left: 8px;
    height: 18px;
    line-height: 20px;
    width: 15%;
}

.reviewed-notification {
    background-color: #dfe5f0;
}

.reviewed-badge {
    background-color: #f7fc62;
    color: black;
    font-size: 12px;
    letter-spacing: .5px;
    padding: 0 4px;
    border-radius: 2px;
    border-color: #17AD49;
    margin-left: 8px;
    height: 18px;
    line-height: 20px;
    width: 15%;
}

.qualified-badge {
    background-color: #028A0F;
    color: white;
    font-size: 12px;
    letter-spacing: .5px;
    padding: 0 4px;
    border-radius: 2px;
    border-color: #17AD49;
    margin-left: 8px;
    height: 18px;
    line-height: 20px;
    width: 15%;
}

.not-qualified-badge {
    background-color: #990F02;
    color: white;
    font-size: 12px;
    letter-spacing: .5px;
    padding: 0 4px;
    border-radius: 2px;
    border-color: #17AD49;
    margin-left: 8px;
    height: 18px;
    line-height: 20px;
    width: 20%;
}

.action-column {
    width: 3%;
}
</style>
