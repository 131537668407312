<template>
  <MDBTable striped hover class="align-middle">
    <thead class="table-dark">
      <tr>
        <th v-for="(item, i) in tableHeaders" :key="i" scope="col" class="tableHeader" colspan="5">
          <div class="d-flex justify-content-between align-items-center pe-3">
            <div class="item-header">
              {{ item.header }}
            </div>
            <div class="refresh-header">
              <span class="input-group-text border-0 refresh-span" @click="getNotificationList">
                <em class="fas fa-redo" />
              </span>
            </div>
          </div>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td colspan="6">
          <form>
            <table>
              <tr>
                <td class="w-25 text-center">
                  <span>Date Added:
                  </span>
                  <input type="date" v-model="filterDate" />
                </td>
                <td>
                  <div class="filter-header">
                    <MDBInput label="Creator Name" type="text" v-model="creatorSearch" />
                  </div>
                </td>
                <td>
                  <div class="filter-header">
                    <MDBSelect v-model:options="statusFilterOptions" v-model:selected="filter"
                      name="statusFilterDropdown" id="statusFilterDropdown" />
                  </div>
                </td>
                <td>
                  <div class="filter-header">
                    <MDBSelect v-model:options="socialMediaTypeFilterOptions" v-model:selected="socialMediaFilter" />
                  </div>
                </td>
                <td class="text-center">
                  <MDBBtn @click="filterNotifList" color="primary" size="sm" :disabled="isSubmitBtnDisabled"
                    class="text-capitalize mb-2">
                    Filter
                  </MDBBtn>
                  <MDBBtn @click="resetFilter" color="primary" size="sm" class="text-capitalize">
                    Reset
                  </MDBBtn>
                </td>
              </tr>
            </table>
          </form>
        </td>
      </tr>
      <tr v-for="(item, i) in notificationList.data" :key="i"
        v-on:dblclick="rowClick(item.id, item.statusTypeId, item.videoLink)">
        <NotificationTable :notification="item" />
      </tr>
    </tbody>
  </MDBTable>
  <!-- paginate -->
  <Paginate :pageType="'notificationList'" :statusId="filter" :socialMediaType="socialMediaFilter"
    :creator="creatorSearch" :dateAdded="filterDate" @paginationData="notificationList = $event"></Paginate>
</template>

<script>
import { onBeforeMount, ref, computed, watch } from "vue";
import axios from "axios";
import { MDBTable, MDBSelect, MDBInput, MDBBtn } from "mdb-vue-ui-kit";
import Paginate from "../components/Pagination/NotifListPagination.vue";
import { useToastStore } from "../store/toastStore";
import NotificationTable from "@/components/DailyVideoUpdateNotificationTableRow.vue";

export default {
  components: {
    Paginate,
    MDBTable,
    MDBSelect,
    NotificationTable,
    MDBInput,
    MDBBtn,
    //Datepicker
  },
  props: ["data"],
  setup(props) {
    const toastStore = useToastStore();
    const tableHeaders = computed(() => [
      {
        header: "Notifications",
      },
    ]);
    const notificationList = ref({
      data: [],
      pagination: {},
    });

    watch([props], ([newNotifList]) => {
      notificationList.value = newNotifList.data;
    });

    const getNotificationList = async () => {
      filterNotifList();
    };

    const rowClick = async (notificationId, statusId, link) => {
      window.open(link, "_blank", "noreferrer");
      if (statusId == 1) {
        updateNotificationStatus(notificationId);
      }
    };

    const updateNotificationStatus = async (notificationId) => {
      const formData = {
        status: 2,
      };

      var url = "api/notifications/" + notificationId + "/status";
      axios
        .patch(url, formData, {
          headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",
          },
        })
        .then(function (response) {
          if (response.status == 200) {
            getNotificationList();
          }

          if (response.status == 422) {
            toastStore.toastObject = {
              ...toastStore.toastObject,
              ...toastStore.toastStatus.fail,
            };
          }
        })
        .catch(function () {
          toastStore.toastObject = {
            ...toastStore.toastObject,
            ...toastStore.toastStatus.fail,
          };
        });
    };

    const socialMediaTypeFilterOptions = ref([
      { text: "Social Media", value: 0 },
      { text: "Facebook", value: 1 },
      { text: "Instagram", value: 2 },
      { text: "Tiktok", value: 3 },
      { text: "Youtube", value: 4 },
      { text: "Douyin", value: 5 },
      { text: "Kuaishou", value: 6 },
    ]);

    const statusFilterOptions = ref([
      { text: "Status", value: 0 },
      { text: "New", value: 1 },
      { text: "Viewed", value: 2 },
      { text: "Qualified", value: 4 },
      { text: "Not Qualified", value: 5 },
      { text: "Ingested", value: 3 },
    ]);

    const filter = ref(0);
    const filterChange = async () => {
      var url = "api/notifications/list";
      var statusQuery = "?statusId=" + filter.value;
      if (filter.value !== 0) {
        url += statusQuery;
      }

      getNotificationListCommon(url);
    };

    const getNotificationListCommon = async (url) => {
      await axios
        .get(url)
        .then(function (response) {
          notificationList.value.data = response.data.data;
          notificationList.value.pagination = response.data.pagination;
        })
        .catch(function () {
          toastStore.toastObject = {
            ...toastStore.toastObject,
            ...toastStore.toastStatus.fail,
          };
        });
    };

    const socialMediaFilter = ref("");
    const creatorSearch = ref("");
    const filterDate = ref("");
    watch([filter, socialMediaFilter, creatorSearch, filterDate], () => {
      notificationList.value = {
        data: { ...notificationList.value.data },
        pagination: { ...notificationList.value.pagination, currentPage: 1 },
      };
    });

    const filterNotifList = async () => {
      var url = "api/notifications/list?";
      var currentPage = notificationList.value.pagination.currentPage;
      if (currentPage) {
        var currentPageQuery = "pageNumber=" + currentPage;
        url += formatUrl(url, currentPageQuery);
      }
      if (filter.value && filter.value !== 0) {
        var statusQuery = "statusId=" + filter.value;
        url += formatUrl(url, statusQuery);
      }

      if (socialMediaFilter.value && socialMediaFilter.value !== 0) {
        var socialMediaTypeQuery = "socialMediaType=" + socialMediaFilter.value;
        url += formatUrl(url, socialMediaTypeQuery);
      }

      if (creatorSearch.value !== "") {
        var creatorQuery = "creator=" + creatorSearch.value;
        url += formatUrl(url, creatorQuery);
      }

      if (filterDate.value !== "") {
        var dateQuery = "dateAdded=" + filterDate.value;

        url += formatUrl(url, dateQuery);
      }

      getNotificationListCommon(url);
    };

    const isSubmitBtnDisabled = computed(
      () =>
        filter.value == 0 &&
        socialMediaFilter.value == 0 &&
        creatorSearch.value == "" &&
        filterDate.value == ""
    );

    const formatUrl = (currentUrl, urlToAdd) => {
      if (currentUrl.substr(currentUrl.length - 1) === "?") {
        return urlToAdd;
      } else {
        return "&" + urlToAdd;
      }
    };

    const resetFilter = () => {
      filter.value = 0;
      socialMediaFilter.value = 0;
      creatorSearch.value = "";
      filterDate.value = "";

      /*this.socialMediaTypeFilterOptions.forEach(option => {
                    option.selected = false;
                });
                this.$set(this.socialMediaTypeFilterOptions[0], 'selected', true);
                this.socialMediaTypeFilterOptions.sort();*/

      /*statusFilterOptions.forEach(option => {
                    option.selected = false;
                });
                statusFilterOptions.$set(statusFilterOptions[0], 'selected', true);
                statusFilterOptions.sort();*/

      document.getElementById("statusFilterDropdown").value = 0;

      var url = "api/notifications/list?";
      getNotificationListCommon(url);
    };

    onBeforeMount(() => {
      getNotificationList();
      //getStatusList();
    });

    return {
      tableHeaders,
      notificationList,
      rowClick,
      getNotificationList,
      statusFilterOptions,
      filterChange,
      filter,
      socialMediaFilter,
      creatorSearch,
      socialMediaTypeFilterOptions,
      filterDate,
      filterNotifList,
      isSubmitBtnDisabled,
      resetFilter,
    };
  },
};
</script>

<style scoped>
:root {
  --white: #ffffff;
  --primary: #fc5818;
  --secondary: #e2e6ef;
  --accent: #02061b;
}

.tableHeader {
  text-align: left;
  font-size: 25px;
}

.inner-table-header {
  display: flex;
  width: 100%;
}

.item-header {
  width: 97%;
}

.filter-header {
  width: 100%;
  margin: auto;
  padding: 0 10px;
}

.refresh-header {
  width: 3%;
}

.refresh-span :hover {
  cursor: pointer;
}
</style>
