<template>
    <LayoutNew>
        <h1 class="text-center">Daily Video Update Notifications</h1>
        <MDBCard class="p-4 pb-3">
            <MDBCardBody class="overflow-auto p-0">
                <NotificationTable :data="notificationList" />
            </MDBCardBody>
        </MDBCard>
    </LayoutNew>
</template>

<script setup>
import { MDBCard, MDBCardBody } from "mdb-vue-ui-kit";
import { ref } from "vue";
import LayoutNew from "@/views/v2/LayoutNew.vue";
import NotificationTable from "@/components/DailyVideoUpdateNotificationTableComponent.vue";

const notificationList = ref({
    data: [],
    pagination: {},
});
</script>
